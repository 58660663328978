import {
  gql,
  DocumentNode,
  TypedDocumentNode
} from "@apollo/client";
import { Company } from "services/GraphQl";
import { BulkCaseCreateInput, HrisConnection } from "services/Interfaces";
interface _Queries {
  // lookupEmployee: DocumentNode
  SEARCH_PLANS: DocumentNode
  recommendPlans: DocumentNode
  INDIVIDUAL_RATE: DocumentNode
  GET_GEOGRAPHY: DocumentNode
  listUsers: DocumentNode
  getRates: DocumentNode
  subsidyCheck: TypedDocumentNode<{ subsidyCheck: { federalSubsidyAmount: number } }, { uid: string }>,
  generateEnrollmentUrl: DocumentNode
  aiRecommendation: DocumentNode
  reScorePlans: DocumentNode
  adminGetCase: TypedDocumentNode<{ adminGetCase: { caseId: string, expiration: string, companyId: string } }, { caseId: string, companyId: string, reason: string }>
  adminGetUser: TypedDocumentNode<{ adminGetUser: { userId: string, expiration: string, companyId: string } }, { userId: string, reason: string }>
  getLinkToken: TypedDocumentNode<{ hris: { merge: { getLinkToken: { token: string } } } }, { companyId: string, companyName: string, emailAddress: string, integrationMethodId: string }>,
  hrisConnectionDetails: TypedDocumentNode<{ hrisConnectionDetails: HrisConnection }, { companyId?: string, adminSearch?: boolean }>
  confirmExitCodeIdentity: TypedDocumentNode<{ confirmExitCodeIdentity: boolean }, { companyId: string, exitCode?: string, lastName: string, birthDate: string, zipCode?: string, workEmail?: string, terminationDate?: string }>,
  getSearchToken: TypedDocumentNode<{ getSearchToken: string }, { companyId?: string, adminSearch?: boolean }>
  // getApplicationSearchToken: TypedDocumentNode<{ getApplicationSearchToken: string }>
  fetchDecryptedSSN: TypedDocumentNode<{ fetchDecryptedSSN: string }, { applicationUserId: string, applicationId: string, householdMemberId: string }>
  getApplicationNotes: TypedDocumentNode<{ getApplicationNotes: { id: string; userName: string; date: string; note: string; }[] }, { applicationUserId: string; applicationId: string }>
  estimateImpact: TypedDocumentNode<{ hris: { csvUpload: { estimateImpact: { new: number, missing: number, total: number, existing: number } } } }, { companyId: string, ids: Array<string> }>
  exitCodeInfo: TypedDocumentNode<{ exitCodeInfo: { firstName: string, documents: number, whenBenefit: number } }, { companyId: string, exitCode: string }>
  caseEstimateImpact: TypedDocumentNode<{ caseEstimateImpact: { employeesToBeCreated: number, employeesExists: number, casesToBeCreated: number, caseExistsNoUpdates: number, casesToBeUpdated: number } }, { csvData: BulkCaseCreateInput[] }>
  getUserBankInfo: TypedDocumentNode<{ getUserBankInfo: { accountNumber: string, routingNumber: string, nameOnAccount: string } }, { userId: string }>
  company: TypedDocumentNode<{ company: Company }, { companyId: string }>;
}

function getQueries(): _Queries {
  let Queries: any = {
    company: gql`
      query Company($companyId: String!) {
        company(companyId: $companyId) {
          code
          address {
            street1
            street2
            city
            state
            zip
          }
          emailDomain
          servicesEnabled
          hrisSetup
          landingPages
          logo
          name
          servicesEnabled
          terminationDate
          zendeskOrganizationId
          createdOn
        }
      }
    `,
    case: gql`
      query Case($companyId: String!, $caseId: String!) {
        case(companyId: $companyId, caseId: $caseId) {
          id
          caseId
          name
          caseType
          companyId
          status
          createdAt
          createdBy
          updatedAt
          updatedBy
          closeDate
          secure
          shareDate
          sharedOn
          scheduled
          shared
          sharedStatus
          klaviyoCaseShared
          klaviyo {
            caseShared {
              date
            }
          }
          zendeskTicket
          zendesk {
            ticketId
            date
          }
          tasks {
            open
            closed
            total
          }
          healthInsuranceDecision
          cobraElectionReason
          exitMeetingDate
          released
          ref
          benefitTier
          lastDay
          terminationDate
          reason
          terminationType
          dataVersion
          syncUser {
            id
            date
          }
          contact {
            id
            firstName
            lastName
            email
            phone
            dateOfBirth
            address {
              street1
              street2
              city
              state
              zip
            }
            salary
            gender
            status
            ethnicity
            maritalStatus
            flsaStatus
            payFrequency
            payRate
            payCurrency
            title
            hireDate
            terminationDate
          }
          cobranding {
            logo
            name
            companyCode
          }
          childCompany {
            id
            name
            logoUrl
            contact {
              name
              email
              phone
            }
          }
          event {
            type
            date
            termination {
              type
              reason
            }
            cobra {
              qualifyingEvent
              estimate
              currentContribution
            }
          }
          household {
            firstName
            lastName
            dateOfBirth
            relationship
          }
          benefits {
            type
            name
            carrier
            monthlyEmployeeContribution
            monthlyEmployerContribution
            benefitEndDate
            dependents {
              firstName
              lastName
              dateOfBirth
              relationship
            }
          }
          services {
            type
            whenBenefitAmount
          }
          whenBenefit {
            status
            hasBenefit
            benefitTier
            amount
            releasedDate
            detail {
              total
              amountSpent
              amountPending
              amountRemaining
              amountForfeited
            }
          }
          healthInsuranceDecisionDetail
          assignee
          syncStatus
          syncedAt
        }
      }
    `,
    cases: gql`
      query Cases($limit: Int, $offset: Int, $filters: CasesFiltersInput) {
        cases(limit: $limit, offset: $offset, filters: $filters) {
          id
          caseId
          name
          caseType
          companyId
          status
          createdAt
          createdBy
          updatedAt
          updatedBy
          closeDate
          secure
          shareDate
          sharedOn
          scheduled
          shared
          sharedStatus
          klaviyoCaseShared
          klaviyo {
            caseShared {
              date
            }
          }
          zendeskTicket
          zendesk {
            ticketId
            date
          }
          tasks {
            open
            closed
            total
          }
          healthInsuranceDecision
          cobraElectionReason
          exitMeetingDate
          released
          ref
          benefitTier
          lastDay
          terminationDate
          reason
          terminationType
          dataVersion
          syncUser {
            id
            date
          }
          contact {
            id
            firstName
            lastName
            email
            phone
            dateOfBirth
            address {
              street1
              street2
              city
              state
              zip
            }
            salary
            gender
            status
            ethnicity
            maritalStatus
            flsaStatus
            payFrequency
            payRate
            payCurrency
            title
            hireDate
            terminationDate
          }
          cobranding {
            logo
            name
            companyCode
          }
          childCompany {
            id
            name
            logoUrl
            contact {
              name
              email
              phone
            }
          }
          event {
            type
            date
            termination {
              type
              reason
            }
            cobra {
              qualifyingEvent
              estimate
              currentContribution
            }
          }
          household {
            firstName
            lastName
            dateOfBirth
            relationship
          }
          benefits {
            type
            name
            carrier
            monthlyEmployeeContribution
            monthlyEmployerContribution
            benefitEndDate
            dependents {
              firstName
              lastName
              dateOfBirth
              relationship
            }
          }
          services {
            type
            whenBenefitAmount
          }
          whenBenefit {
            status
            hasBenefit
            benefitTier
            amount
            releasedDate
            detail {
              total
              amountSpent
              amountPending
              amountRemaining
              amountForfeited
            }
          }
          healthInsuranceDecisionDetail
          assignee
          syncStatus
          syncedAt
        }
      }
    `,
    getUserBankInfo: gql`
      query getUserBankInfo($userId: String!) {
        getUserBankInfo(userId: $userId) {
          accountNumber
          routingNumber
          nameOnAccount
        }
      }
    `,
    exitCodeInfo: gql`
      query exitCodeInfo($companyId: String!, $exitCode: String!) {
        exitCodeInfo(companyId: $companyId, exitCode: $exitCode) {
          firstName
          documents
          whenBenefit
        }
      }
    `,
    messages: gql`
      query messages {
        messages {
          id
          text
          user
          timestamp
        }
      }
    `,
    adminGetCase: gql`
      query adminGetCase($caseId: String!, $companyId: String!, $reason: HIPAAViewReasons) {
        adminGetCase(caseId: $caseId, companyId: $companyId, reason: $reason) {
          caseId
          companyId
          expiration
        }
      }
    `,
    adminGetUser: gql`
      query adminGetUser($userId: String!, $reason: HIPAAViewReasons) {
        adminGetUser(userId: $userId, reason: $reason) {
          userId
          companyId
          expiration
        }
      }
    `,
    estimateImpact: gql`
      query Hris($companyId: String, $ids: [String]) {
        hris {
          csvUpload {
            estimateImpact(companyId: $companyId, ids: $ids) {
              missing
              new
              total
              existing
            }
          }
        }
      }
    `,
    caseEstimateImpact: gql`
      query caseEstimateImpact($csvData: [BulkCaseCreateInput!]!) {
        caseEstimateImpact(csvData: $csvData) {
          employeesToBeCreated
          employeesExists
          casesToBeCreated
          caseExistsNoUpdates
          casesToBeUpdated
        }
      }
    `,
    getSearchToken: gql`
      query getSearchToken($companyId: String, $adminSearch: Boolean) {
        getSearchToken(companyId: $companyId, adminSearch: $adminSearch)
      }
    `,
    //   getApplicationSearchToken: gql`
    //   query getApplicationSearchToken {
    //     getApplicationSearchToken
    //   }
    // `,
    fetchDecryptedSSN: gql`
      query fetchDecryptedSSN($applicationUserId: String!, $applicationId: ID!, $householdMemberId: String!) {
        fetchDecryptedSSN(
          applicationUserId: $applicationUserId
          applicationId: $applicationId
          householdMemberId: $householdMemberId
        )
      }
    `,
    getApplicationNotes: gql`
      query getApplicationNotes($applicationUserId: String!, $applicationId: ID!) {
        getApplicationNotes(applicationUserId: $applicationUserId, applicationId: $applicationId) {
          id
          userName
          date
          note
        }
      }
    `,
    hrisConnectionDetails: gql`
      query hrisConnectionDetails($connectionId: String, $companyId: String) {
        hrisConnectionDetails(connectionId: $connectionId, companyId: $companyId) {
          name
          logo
          squareLogo
          status
          lastImport
          createdOn
        }
      }
    `,
    confirmExitCodeIdentity: gql`
      query confirmExitCodeIdentity(
        $companyId: String!
        $exitCode: String
        $lastName: String!
        $birthDate: String!
        $zipCode: String
        $workEmail: String
        $terminationDate: String
      ) {
        confirmExitCodeIdentity(
          companyId: $companyId
          exitCode: $exitCode
          lastName: $lastName
          birthDate: $birthDate
          zipCode: $zipCode
          workEmail: $workEmail
          terminationDate: $terminationDate
        )
      }
    `,
    reScorePlans: gql`
      query reScorePlans($effectiveDate: String, $uid: String) {
        reScorePlans(effectiveDate: $effectiveDate, uid: $uid) {
          total
        }
      }
    `,
    aiRecommendation: gql`
      query aiRecommendation($effectiveDate: String, $planId: String) {
        aiRecommendation(effectiveDate: $effectiveDate, planId: $planId) {
          aiScore
          pros
          cons
          headline
          explanation
        }
      }
    `,
    generateEnrollmentUrl: gql`
      query generateEnrollmentUrl($effectiveDate: String, $planId: String, $carrierId: String) {
        generateEnrollmentUrl(effectiveDate: $effectiveDate, planId: $planId, carrierId: $carrierId) {
          redirectUrl
        }
      }
    `,
    getLinkToken: gql`
      query GetLinkToken(
        $companyId: String!
        $companyName: String!
        $emailAddress: String!
        $integrationMethodId: String!
      ) {
        hris {
          merge {
            getLinkToken(
              companyId: $companyId
              companyName: $companyName
              emailAddress: $emailAddress
              integrationMethodId: $integrationMethodId
            ) {
              token
            }
          }
        }
      }
    `,
    getRates: gql`
      query getRates($effectiveDate: String, $uid: String, $source: String) {
        getRates(effectiveDate: $effectiveDate, uid: $uid, source: $source) {
          total
        }
      }
    `,
    subsidyCheck: gql`
      query SubsidyCheck($uid: String!) {
        subsidyCheck(uid: $uid) {
          federalSubsidyAmount
        }
      }
    `,
    //   lookupEmployee: gql`
    //   query lookupEmployee($company: String, $lastName: String, $dateOfBirth: String, $email: String){
    //   lookupEmployee(company: $company, lastName: $lastName, email: $email, dateOfBirth: $dateOfBirth){
    //     firstName
    //     lastName
    //     employeeId
    //     benefit
    //   }
    // }
    // `,
    recommendPlans: gql`
      query recommendPlans($filters: String) {
        recommendPlans(filters: $filters) {
          plans {
            lowestDeductible {
              premium
              name
              provider
              id
              source
              type
              maxOutOfPocket
              deductible
              metal
              rating
              market
              diseasePrograms
              benefits {
                name
                covered
                inNetwork
                outOfNetwork
              }
              benefitUrl
              formularyUrl
              brochureUrl
              networkUrl
            }
            lowestPremium {
              premium
              name
              provider
              id
              source
              type
              maxOutOfPocket
              deductible
              metal
              rating
              market
              diseasePrograms
              benefits {
                name
                covered
                inNetwork
                outOfNetwork
              }
              benefitUrl
              formularyUrl
              brochureUrl
              networkUrl
            }
            lowestOutOfPocket {
              premium
              name
              provider
              id
              source
              type
              maxOutOfPocket
              deductible
              metal
              rating
              market
              diseasePrograms
              benefits {
                name
                covered
                inNetwork
                outOfNetwork
              }
              benefitUrl
              formularyUrl
              brochureUrl
              networkUrl
            }
          }
          total
        }
      }
    
  `,
    getCompanySettings: gql`
      query getCompanySettings($companyId: String!) {
        company(companyId: $companyId) {
          name
          address {
            street1
            street2
            city
            state
            zip
          }
          headquarterState
          planRenewalMonth
          activationDate
          terminationDate
          customerSuccessManager
          cobraAdministrationProvider
          emailDomain
          logo
          fein
          companyPointOfContact {
            name
            email
            phone
            title
          }
          employeeCount
          externalCompanyIdentifier
          servicesEnabled
          whenBenefitUseOnCobra
          automation {
            employerPlatformLessWorkflowEnable
            numberOfDays
            errorNotificationContactEmail
          }
          timing {
            betweenRequests {
              value
              measure
            }
            firstRequest {
              value
              measure
            }
          }
        }
      }
    `,
  }

  Queries.listUsers = gql`
    query listUsers($filters: String, $page: Int, $limit: Int) {
    listUsers(filters: $filters, page: $page, limit: $limit) {
        users{
        uid
        email
        admin
      }
    }
  }
  `;
  try {
    Queries.GET_GEOGRAPHY = gql`
    query geographyByZip($zipcode: String) {
    geographyByZip(zipcode: $zipcode) {
      zipcode
      name
      fips
      state
    }
  }
  `;

  } catch (err) {
    console.error("GET_GEOGRAPHY error", err);
  }

  try {
    Queries.SEARCH_PLANS = gql`
    query SearchPlansQuery($filters: String, $page: Int) {
    searchPlans(filters: $filters, page: $page) {
      plans{
        premium
        name
        provider
        id
        source
        type
        maxOutOfPocket
        deductible
        metal
        rating
        market
        diseasePrograms,
          benefits {
          name
          covered
          inNetwork
          outOfNetwork
        }
        benefitUrl,
          formularyUrl,
          brochureUrl,
          networkUrl
      }
      total
    }
  }
  `;
  } catch (err) {
    console.error("SEARCH_PLANS error", err);
  }
  return Queries;
}

const Queries: _Queries = getQueries();
export { Queries };